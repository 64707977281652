export class DeviceUseRecordModel extends BaseModel {
  constructor () {
    super();
    this.id = '';
    this.deviceNumber = ''; // 设备编码
    this.imei = ''; // IMEI号
    this.name = ''; // 设备名称
    this.provinceName = ''; // 省份名称
    this.cityName = ''; // 城市名称
    this.communityName = ''; // 小区名称
    this.aaaa = ''; // 设备商名称
    this.bbbb = ''; // 设备型号编码
    this.cccc = ''; // 设备型号名称
    this.dddd = ''; // 端口类别
    this.eee = ''; // 使用端口号
    this.fff = ''; // 结算功率
    this.ggg = ''; // 所用电量
    this.hhh = ''; // 充电电量
    this.iii = ''; // 剩余时间(分钟)
    this.jjj = ''; // 剩余电量(千瓦)
    this.kkk = ''; // 充电结束原因
    this.lll = ''; // 退款金额
    this.mmm = ''; // 同步时间
    this.nnn = ''; // 数据来源

    // 前端专用 ==========>
    // 是否已选中
    this._checked = false;
    // 勾选的主键
    this._checkId = '';
    // 前端专用 ==========>
  }

  initData (resData) {
    super.initData(resData);
    this._checkId = this.id;
    return this;
  }

  static createFakeDataList (len = 10) {
    const list = [];
    for (let i = 0; i < len; i++) {
      const dic = {
        name: BaseModel.getFakeTitle(Math.ceil(Math.random() * 50)),
        id: '000101010' + i,
        tel: '13904288000',
        provinceName: BaseModel.getFakeTitle(4) + '省',
        cityName: BaseModel.getFakeTitle(6) + '市',
        communityName: BaseModel.getFakeTitle(6) + '区',
        aaaa: BaseModel.getFakeTitle(6),
        bbbb: BaseModel.getFakeTitle(6),
        cccc: BaseModel.getFakeTitle(6),
        dddd: BaseModel.getFakeNumber(10, 0),
        eee: BaseModel.getFakeNumber(10, 0),
        fff: BaseModel.getFakeNumber(50, 0) + 200,
        ggg: BaseModel.getFakeNumber(50, 0) + 200,
        hhh: BaseModel.getFakeNumber(50, 0) + 200,
        iii: BaseModel.getFakeNumber(50, 0) + 200,
        jjj: BaseModel.getFakeNumber(50, 0) + 200,
        kkk: BaseModel.getFakeTitle(6),
        lll: BaseModel.getFakeNumber(2),
        mmm: '2023-01-02 12:12:12',
        nnn: '小橙',
        deviceNumber: BaseModel.getFakeNumber(99999, 0)
      };
      list.push(dic);
    }
    return {
      rows: list,
      total: 30
    };
  }

  static createAdditionModel () {
    return new this();
  }
}
