<!--
    Created by 程雨喵'mac on 2024/4/15.
    Copyright © 2024年 云柜-金星晖. All rights reserved.
    界面名称：充电管理-使用记录
-->
<style lang="less">
#device-use-record {
  .text-finish-color {
    color: @finishColor;
    font-weight: 600;
  }
}
</style>

<template>
  <div id="device-use-record">
    <!-- 查询条件 -->
    <div>
      <yg-search-area
        ref="YgSearchArea"
        :show-info="false"
        :value="searchOption"
        @on-search="_clickSearch"
      />
    </div>
    <div>
      <yg-setting-area
        :items="settingItems"
        :total="page.total"
        :export-amount="settingExportIdList.length"
        :export-select-id-list="settingExportIdList"
        :export-condition="settingExportCondition"
        @on-click="_clickSetting"
      />
    </div>
    <div
      class="padding-primary-top"
    >
      <yg-table
        :columns="tableColumns"
        :data="tableData"
        :page="page"
        :row-class-name="mixinTableRowClassName"
        @on-page-change="onChangePage"
        @on-selection="onChangeSelection"
        @on-row-expand="mixinTableOnRowExpand"
      />
    </div>
  </div>
</template>

<script>
// import DeviceApiSet from '@/request/api/DeviceApiSet';
// import DpApiSet from '@/request/api/DpApiSet';
import { DeviceUseRecordModel } from './model/DeviceUseRecordModel';
import pageTableExpandMixin from '@/libs/mixins/pageTableExpand';
import ColumnMixin from './mixin/tableColumn';

export default {
  components: {},
  mixins: [ColumnMixin, pageTableExpandMixin],
  data () {
    return {
      searchOption: {
        deviceNoLabel: BaseSearchModel.initData('设备型号名称', 'deviceNoLabel', 'Input').setLabelSelect([
          { value: 'deviceNumber', label: '设备编码' },
          { value: 'name', label: '设备名称' },
          { value: 'deviceName', label: '设备型号名称' },
          { value: 'imei', label: 'IMEI号' }
        ]),
        area: BaseSearchModel.initData('物业/小区/点位', 'area', 'YgSiteCascader').setData(1),
        deviceSource: BaseSearchModel.initData('数据来源', 'deviceSource', 'YgGlobalSelect').setData('chargingPileType'),
        time: BaseSearchModel.initData('同步时间', 'time', 'DatePickerRange').setDatePickerElevator([])
      },
      settingExportIdList: [],
      settingExportCondition: {},
      settingItems: [
        BaseSettingModel.initData('导出列表', 'downloadList', '').setExport().setExportReportType(6)
      ],
      page: { current: 1, total: 0 },
      tableData: []
    };
  },
  created () {
    // 配置展开项内容
    this.mixinTableExpandSetColumnOperation(2, [
      {
        title: '型号信息',
        col: '50',
        items: [
          { col: '25', label: '设备商名称:', key: 'manufacturersDesc', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '设备型号编码:', key: 'deviceModel', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '设备型号名称:', key: 'deviceName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '设备端口类别:', key: 'portCategory', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      },
      {
        title: '所在区域',
        col: '50',
        items: [
          { col: '25', label: '所在省份:', key: 'provinceName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '所在城市:', key: 'cityName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '所在小区:', key: 'communityName', canCopy: false, canTooltip: true, canHidePhone: false },
          { col: '25', label: '所在点位:', key: 'siteName', canCopy: false, canTooltip: true, canHidePhone: false }
        ]
      }
    ]);
  },
  methods: {
    /** ******************* 工具方法开始 ******************* **/
    /** ******************* 工具方法结束 ******************* **/

    /** ******************* 回调事件开始 ******************* **/
    onChangePage (page) {
      this.page.current = page;
      this.requestData();
    },
    onChangeSelection (list) {
      // 1.先把本页的数据清掉
      let selectList = this.settingExportIdList.filter(v => this.tableData.findIndex(v1 => v1._checkId === v._checkId) === -1);
      if (list.length > 0) {
        // 本页有勾选项放进去
        selectList = [...selectList, ...list];
      }
      this.settingExportIdList = selectList;
    },
    /** ******************* 回调事件结束 ******************* **/

    /** ******************* 点击事件开始 ******************* **/
    _clickSearch (option) {
      console.log(option);
      this.searchOption = option;
      this.page.current = 1;
      this.requestData();
    },
    _clickSetting (key) {},
    /** ******************* 点击事件结束 ******************* **/

    /** ******************* 数据请求开始 ******************* **/
    requestData () {
      const s = this.searchOption;
      const api = {};
      api.params = {
        deviceSource: s.deviceSource.value,
        propertyCompanyId: s.area.value[0] || '',
        provinceName: s.area.value[1] || '',
        cityName: s.area.value[2] || '',
        communityId: s.area.value[3] || '',
        siteId: s.area.value[4] || '',
        timeStart: s.time.value[0],
        timeEnd: s.time.value[1],
        pageNo: this.page.current,
        pageSize: this.$config.pageSize
      };
      // 筛选中可下拉的label
      api.params[s.deviceNoLabel.labelSelectValue] = s.deviceNoLabel.value;
      this.settingExportCondition = api.params;
      this.tableData = [];
      this.page.total = 0;
      setTimeout(() => {
        const resData = DeviceUseRecordModel.createFakeDataList();
        this.page.total = resData.total;
        const list = DeviceUseRecordModel.initListWithDataList(resData.rows);
        this.tableData = this.mixinTableExpandDataSetExpand(list);
      }, 100);
      // this.$http(api).then(res => {
      //   const resData = res.data;
      //   this.page.total = resData.total;
      //   this.tableData = DeviceUseRecordModel.initListWithDataList(resData.rows);
      // });
    }
    // 设备商列表
    // requestDeviceProvider () {
    //   const api = DpApiSet.dpList;
    //   api.params = {
    //     pageSize: 10000
    //   };
    //   this.$http(api).then(res => {
    //     const resData = res.data.rows || [];
    //     resData.forEach(v => {
    //       v.value = v.id;
    //       v.label = v.deviceProviderName;
    //     });
    //     this.searchOption.deviceProviderId.setData(resData);
    //     this.$refs.YgSearchArea.$refresh();
    //   });
    // }
    /** ******************* 数据请求结束 ******************* **/
  }
};
</script>
