export default {
  data () {
    return {
      tableColumns: [
        BaseTableColumn.initData('', '123').setSelection().setWidth(32).setAlign('center'),
        BaseTableColumn.initData('index'),
        BaseTableColumn.initData('', '设备信息').setWidth(220).setKeyValueList([
          { label: '设备编码:', labelWidth: 80, key: 'deviceNumber', attr: { canCopy: true } },
          { label: 'IMEI号:', labelWidth: 80, key: 'imei', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('name', '设备名称').setWidth(100).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('area', '设备端口').setWidth(120).setKeyValueList([
          { label: '端口号:', labelWidth: 60, key: 'eee', attr: { canTooltip: true } },
          { label: '结算功率:', labelWidth: 60, key: 'fff', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('area', '电量').setWidth(120).setKeyValueList([
          { label: '所用电量:', labelWidth: 60, key: 'ggg', attr: { canTooltip: true } },
          { label: '充电电量:', labelWidth: 60, key: 'hhh', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('area', '剩余').setWidth(180).setKeyValueList([
          { label: '剩余时间(分钟):', labelWidth: 120, key: 'iii', attr: { canTooltip: true } },
          { label: '剩余电量(千瓦):', labelWidth: 120, key: 'jjj', attr: { canTooltip: true } }
        ]),
        BaseTableColumn.initData('kkk', '充电结束原因', 100).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('money', '金额').setWidth(120).setKeyValueList([
          { label: '退款金额:', labelWidth: 60, key: 'lll', attr: { canPrice: true, className: 'text-finish-color' } }
        ]),
        BaseTableColumn.initData('mmm', '同步时间', 130).setSpan({ canTooltip: true }),
        BaseTableColumn.initData('nnn', '数据\n来源').setWidth(60).setSpan({ canTooltip: true })
      ]
    };
  }
};
